import { Routes, Route } from "react-router-dom";
import ABMProductos from "../pages/admin/productos/ABMProductos";
import ABMCategorias from "../pages/admin/categorias/ABMCategorias";
import Stock from "../pages/admin/stock/Stock";
import MovimientosStock from "../pages/admin/stock/MovimientosStock";
import TablaPrecios from "../pages/admin/productos/TablaPrecios";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "../pages/loginPage/LoginPage";
import AdminLayout from "../layouts/AdminLayout";
import QrPage from "../pages/qrPage/QrPage";
import {
    Category as CategoryIcon, MenuBook as MenuBookIcon, Fastfood as FastfoodIcon,
    Settings as SettingsIcon, QrCode2 as QrCode2Icon, ColorLens as ColorLensIcon,
    Wifi as WifiIcon, FormatListNumbered as FormatListNumberedIcon, PriceChange as PriceChangeIcon,
    Inventory2 as Inventory2Icon, PictureAsPdf as PictureAsPdfIcon, SyncAlt as SyncAltIcon,
    InventorySharp as InventorySharpIcon
} from "@mui/icons-material";
import Wifi from "../pages/configuracionPage/Wifi";
import Personalizacion from "../pages/configuracionPage/Personalizacion";
import { useContext, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import Impresion from "../pages/admin/impresion/Impresion";
import ABMDetalles from "../pages/admin/detalles/ABMDetalles";
import Pedidos from "../pages/admin/pedidos/Pedidos";
import EstadisticasProductos from "../pages/admin/estadisticas/EstadisticasProductos"
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EstadisticasProductosGraficos from "../pages/admin/estadisticas/EstadisticasProductosGraficos";
import Descuentos from "../pages/admin/descuentos/Descuentos";
import ChecklistIcon from '@mui/icons-material/Checklist';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import OrdenarCategorias from "../pages/admin/categorias/OrdenarCategorias";
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import Contacto from "../pages/admin/contacto/Contacto";
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

export const usePaginas = () => {
    const { empresaActual, tipo, planes } = useContext(UserContext);

    const paginas = [
        {
            nombre: "Carta",
            seccion: "",
            url: `/${empresaActual?.nombre}`,
            icon: <MenuBookIcon />,
        },
        {
            nombre: "Categorias",
            icon: <CategoryIcon />,
            seccion: [
                {
                    "nombre": "Listado Categorias", "url": "/admin/categorias", "icon": <FormatListNumberedIcon />,
                    "component": <ABMCategorias />, "componentUrl": "/categorias/"
                },
                {
                    "nombre": "Ordenar", "url": "/admin/categorias/ordenar", "icon": <LowPriorityIcon />,
                    "component": <OrdenarCategorias />, "componentUrl": "/categorias/ordenar/"
                }
            ]
        },
        {
            nombre: "Productos",
            icon: <FastfoodIcon />,
            seccion: [
                {
                    "nombre": "Listado Productos", "url": "/admin/productos/listado", "icon": <FormatListNumberedIcon />,
                    "component": <ABMProductos />, "componentUrl": "/productos/listado"
                },
                {
                    "nombre": "Precios", "url": "/admin/productos/precios", "icon": <PriceChangeIcon />,
                    "component": <TablaPrecios />, "componentUrl": "/productos/precios"
                }
            ],
        },
        {
            nombre: "Configuraciones",
            icon: <SettingsIcon />,
            seccion: [
                {
                    "nombre": "Personalizacion", "url": "/admin/personalizacion/", "icon": <ColorLensIcon />,
                    "component": <Personalizacion />, "componentUrl": "/personalizacion/"
                },]
        },
        {
            nombre: "QR",
            seccion: "qr",
            url: "/admin/qr/",
            icon: <QrCode2Icon />,
            component: <QrPage />,
            componentUrl: "/qr/"
        },
        // {
        //     nombre: "Contacto",
        //     seccion: "contacto",
        //     url: "/admin/contacto/",
        //     icon: <PhoneCallbackIcon />,
        //     component: <Contacto />,
        //     componentUrl: "/contacto/"
        // },
    ];

    if (tipo === "Carta") {
        paginas[3].seccion.push({
            "nombre": "Wi Fi", "url": "/admin/wifi/", "icon": <WifiIcon />,
            "component": <Wifi />, "componentUrl": "/wifi/"
        });
    }

    if (planes.includes('7')) {
        paginas.push({
            "nombre": "Descuentos", "url": "/admin/descuentos", "icon": <CurrencyExchangeOutlinedIcon />,
            "component": <Descuentos />, "componentUrl": "/descuentos"
        });
    }

    if (planes.includes('2') || planes.includes('5')) {
        paginas.push({
            nombre: "Pedidos",
            seccion: "pedidos",
            url: "/admin/pedidos/",
            icon: <Inventory2Icon />,
            component: <Pedidos />,
            componentUrl: "/pedidos/"
        });
    };

    if (planes.includes('4')) {
        paginas.push({
            nombre: "Estadisticas",
            icon: <QueryStatsIcon />,
            seccion: [
                {
                    "nombre": "Listar", "url": "/admin/estadisticas/listado/", "icon": <FormatListNumberedIcon />,
                    "component": <EstadisticasProductos />, "componentUrl": "/estadisticas/listado/"
                },

                {
                    "nombre": "Graficos", "url": "/admin/estadisticas/graficos/", "icon": <DonutLargeIcon />,
                    "component": <EstadisticasProductosGraficos />, "componentUrl": "/estadisticas/graficos/"
                },
            ]
        });
    }

    if (planes.includes('6')) {
        paginas[2].seccion.push(
            {
                "nombre": "Detalles", "url": "/admin/productos/detalles", "icon": <ChecklistIcon />,
                "component": <ABMDetalles />, "componentUrl": "/productos/detalles"
            }
        );
    }

    return paginas;
};

export default function AdminDashboard() {
    const paginas = usePaginas();

    return (
        <Routes>
            <Route element={<PrivateRoute />}>
                <Route element={<AdminLayout />}>
                    {paginas.map((pagina, index) => (
                        Array.isArray(pagina.seccion) ?
                            pagina.seccion.map((seccion, index) => (
                                <Route key={index} path={seccion.componentUrl} element={seccion.component} />
                            ))
                            :
                            <Route key={index} path={pagina.componentUrl} element={pagina.component} />
                    ))}
                    <Route path="*" element={<h1>404</h1>} />
                </Route>
            </Route>

            <Route path="/login/" element={<LoginPage />} />
        </Routes>
    )
}
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { SettingsContext } from '../../../../../contexts/SettingsContext';
import { Select, MenuItem, useMediaQuery, Button, FormControl, InputLabel } from '@mui/material';
import { Grid, Typography, Box, Modal, IconButton, Tooltip } from '@mui/material';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import CloseIcon from "@mui/icons-material/Close";
import useFetch, { host } from "../../../../../Utils/Fetchs";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SeparadorMiles from '../../../../../Utils/SeparadorMiles';
import Star from '@mui/icons-material/Star';
import Divider from '@mui/material/Divider';

function ModalProducto(props) {
    const { open, onClose, children } = props

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "84%", sm: "70%", md: "30%" },
                    height: "90%",
                    boxShadow: 24,
                    borderRadius: "25px",
                    "&:focus": {
                        outline: "none",
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Tooltip title="Cerrar" arrow>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            top: 20,
                            right: 20,
                            backgroundColor: "rgba(255, 255, 255, .8)",
                            "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.6)",
                            },
                            zIndex: 1100,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
                <Box sx={{
                    backgroundColor: "background.paper",
                    height: "100%",
                    borderRadius: "25px",
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}>
                    {children}
                </Box>
            </Box>
        </Modal>
    );
};

export default function Tipo2({ producto, index, click, setClick, descuentos }) {
    const { settings } = useContext(SettingsContext);
    const isMobile = useMediaQuery('(max-width: 450px)');

    const [isInCart, setIsInCart] = useState(false);

    const [fullScreenImage, setFullScreenImage] = useState(false);
    const fullScreenImageStyle = {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        animation: "scaleUp 0.5s ease-in-out",
             border: "none",
        outline: "none"
    };

    const { carrito, setCarrito, addItem, planes, isInCarrito, decreaseItem, getCantidad, getPrecioTotal } = useContext(SettingsContext);

    useEffect(() => {
        setIsInCart(isInCarrito(getProductoWDetails()) !== -1);
    }, [carrito]);

    const [selectedDetails, setSelectedDetails] = useState(producto.detalles ? producto.detalles.map(detalle => {
        let newDetalle = { ...detalle };
        let newTipos = newDetalle.tipos.map((tipo, index) => {
            return { ...tipo, selected: index === 0 };
        });
        newDetalle.tipos = newTipos;
        return newDetalle;
    }) : []);

    function handleActualizarPrecio() {
        setTotalPrice(getPrecioTotal(producto, selectedDetails))
    }

    useEffect(() => {
        handleActualizarPrecio();
    }, []);

    function getProductoWDetails() {
        let newProducto = { ...producto };
        newProducto.detalles = [...selectedDetails.map(detalle => {
            let newDetalle = { ...detalle };
            newDetalle.tipos = [...detalle.tipos.map(tipo => {
                return { ...tipo };
            })];
            return newDetalle;
        })];
        return newProducto;
    }

    const [totalPrice, setTotalPrice] = useState(producto.Precio);

    const calculateDiscountedPrice = (originalPrice, descuento) => {
        const discount = originalPrice * (descuento.porcentaje / 100);
        return originalPrice - discount;
    };

    return (
        <ModalProducto
            open={click}
            onClose={() => { setClick(false); setSelectedDetails([]); setTotalPrice(producto.Precio) }}
        >
            <Box sx={{
                height: "auto",
                minHeight: "45%",
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
            }}>
                {producto?.Imagen ?
                    <img src={host + producto?.Imagen} alt={producto.Nombre} style={{
                        width: "100%",
                        height: "auto",
                        minHeight: "200px",
                        maxHeight: "45vh",
                        objectFit: "cover",
                        borderRadius: '25px 25px 0 0',
                    }} 
                    onClick={() => { setFullScreenImage(true) }}
                    />
                    :
                    settings.tipo === 'Carta' ?
                        <FastfoodIcon style={{ fontSize: '900%' }} /> :
                        <ShoppingBagIcon style={{ fontSize: '900%' }} />
                }

                <Typography variant="h5" fontFamily={settings?.font_family}
                    sx={{
                        color: settings?.font_color,
                        zIndex: 100,
                        fontSize: isMobile ? '1.3em' : '1.8em',
                        fontWeight: 'bold',
                        padding: '10px',
                        background: "white",
                        wordBreak: 'break-word',
                    }}>
                    {producto.Nombre}
                </Typography>
            </Box>

            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: "15px",
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}>
                <Box sx={{ marginBottom: '15px' }}>
                    {producto?.Precio ? (
                        descuentos.length > 0 ?
                            <Box sx={{ marginBottom: '5px' }}>
                                {producto?.Precio && (
                                    <>
                                        <Typography
                                            variant="h6"
                                            fontFamily={settings?.font_family}
                                            sx={{
                                                color: settings?.font_color,
                                                fontSize: isMobile ? '1.2em' : '1.5em',
                                                marginBottom: '5px'
                                            }}
                                        >
                                            Precio: $ {SeparadorMiles(producto.Precio)}
                                        </Typography>
                                        {descuentos.length > 0 && descuentos.map((descuento, index) => (
                                            <Box key={index}>
                                                <Typography
                                                    variant="h6"
                                                    fontFamily={settings?.font_family}
                                                    sx={{
                                                        color: 'green',
                                                        fontSize: isMobile ? '1em' : '1em',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Precio con descuento({descuento.cantidad_minima}): ${SeparadorMiles(calculateDiscountedPrice(producto.Precio, descuento))}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </>
                                )}
                            </Box>
                            : (
                                planes.includes('5') ?
                                    <Box
                                        sx={{
                                            backgroundColor: 'rgb(255, 252, 245)',
                                            borderRadius: '12px',
                                            padding: '16px',
                                            border: '1px solid rgb(255, 237, 203)'
                                        }}
                                    >
                                        <Box sx={{ marginBottom: 2 }}>
                                            <Typography
                                                variant="body1"
                                                fontFamily={settings?.font_family}
                                                sx={{
                                                    color: 'gray',
                                                    fontSize: '0.9em',
                                                    marginBottom: '4px'
                                                }}
                                            >
                                                Precio regular
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                fontFamily={settings?.font_family}
                                                sx={{
                                                    color: settings?.font_color,
                                                    fontSize: isMobile ? '1.2em' : '1.5em',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                $ {SeparadorMiles(producto.Precio)}
                                            </Typography>
                                        </Box>

                                        <Divider sx={{ my: 2, borderStyle: 'dashed', borderColor: 'rgb(255, 237, 203)' }} />

                                        <Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
                                                <Star color="#EF9900" />
                                                <Typography
                                                    variant="body1"
                                                    fontFamily={settings?.font_family}
                                                    sx={{
                                                        color: '#EF9900',
                                                        fontSize: '0.9em',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    Precio Afiliado FAMM
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2 }}>
                                                <Typography
                                                    variant="h6"
                                                    fontFamily={settings?.font_family}
                                                    sx={{
                                                        color: '#EF9900',
                                                        fontSize: isMobile ? '1.2em' : '1.5em',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    $ {SeparadorMiles(producto.Precio * 0.9)}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        backgroundColor: 'rgb(255, 237, 203)',
                                                        color: '#EF9900',
                                                        fontSize: '0.8em',
                                                        padding: '4px 8px',
                                                        borderRadius: '12px',
                                                        fontWeight: 'medium'
                                                    }}
                                                >
                                                    10% OFF
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    :
                                    <Typography
                                        variant="h6"
                                        fontFamily={settings?.font_family}
                                        sx={{
                                            color: settings?.font_color,
                                            fontSize: isMobile ? '1.2em' : '1.5em',
                                        }}
                                    >
                                        ${SeparadorMiles(producto.Precio)}
                                    </Typography>
                            )
                    ) : null}
                </Box>

                <Box sx={{ marginBottom: '20px' }}>
                    {!producto.TipoDescripcion ?
                        <Typography sx={{
                            color: settings?.font_color,
                            fontFamily: settings?.font_family,
                            fontSize: isMobile ? '1em' : '1.2em',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                        }}>
                            {producto.Descripcion}
                        </Typography>
                        :
                        <>
                            {producto.Descripcion.split('-').map((item, index) => (
                                <li key={index} style={{ padding: '5px' }}>{item}</li>
                            ))}
                        </>
                    }
                </Box>

                {selectedDetails && selectedDetails.length > 0 && (
                    <Box sx={{ marginBottom: '20px' }}>
                        {selectedDetails.map((detalle, index) => (
                            <Box key={index} sx={{ marginBottom: '20px' }}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        id={`select-label-${index}`}
                                        sx={{
                                            backgroundColor: 'background.paper',
                                            padding: '0 5px',
                                        }}
                                    >{detalle.nombre}</InputLabel>
                                    <Select
                                        fullWidth
                                        displayEmpty
                                        labelId={`select-label-${index}`}
                                        id={`select-${index}`}
                                        sx={{
                                            fontFamily: settings?.font_family,
                                        }}
                                        renderValue={(selected) => selected?.nombre}
                                        value={detalle.tipos.find(tipo => tipo.selected)}
                                        onChange={(e) => {
                                            let newSelectedDetails = [...selectedDetails];
                                            newSelectedDetails[index].tipos = newSelectedDetails[index].tipos.map(tipo => {
                                                tipo.selected = tipo.id === e.target.value.id;
                                                return tipo;
                                            });
                                            setSelectedDetails(newSelectedDetails);
                                            handleActualizarPrecio();
                                        }}
                                    >
                                        {detalle.tipos?.map((tipo) => (
                                            <MenuItem
                                                key={tipo.id}
                                                value={tipo}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <span>{tipo.nombre}</span>

                                                {detalle.precio_relativo === true ?
                                                    (tipo.precio !== null && tipo.precio !== 0 && tipo.precio !== undefined && <span>+ ${tipo.precio}</span>) :
                                                    <span>$ {tipo.precio}</span>
                                                }
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ))}

                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography variant="h6" fontFamily={settings?.font_family}
                                sx={{
                                    color: settings?.font_color,
                                    fontSize: isMobile ? '1em' : '1.2em',
                                }}>
                                Precio final: $ {totalPrice}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {planes && planes.includes('1') ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                        transition: 'all 0.3s ease-in-out',
                    }}>
                        {isInCart && (
                            <div
                                style={{
                                    borderRadius: '25px',
                                    height: '35px',
                                    border: 'none',
                                    fontSize: '24px',
                                    fontFamily: settings?.font_family,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: "70%",
                                }}
                            >
                                <IconButton
                                    style={{
                                        cursor: 'pointer',
                                        width: '30%',
                                        height: '100%',
                                        textAlign: 'center',
                                        alignContent: 'center',
                                    }}
                                    onClick={() => {
                                        decreaseItem(getProductoWDetails());
                                    }}
                                >
                                    -
                                </IconButton>
                                <p style={{
                                    fontSize: '20px',
                                }}>{getCantidad(getProductoWDetails())}</p>
                                <IconButton
                                    style={{
                                        cursor: 'pointer',
                                        width: '30%',
                                        height: '100%',
                                        textAlign: 'center',
                                        alignContent: 'center',
                                    }}
                                    onClick={() => {
                                        addItem(getProductoWDetails());
                                    }}
                                >
                                    +
                                </IconButton>
                            </div>
                        )}
                        <button
                            style={{
                                backgroundColor: settings?.background_color3,
                                borderRadius: '25px',
                                color: settings?.color3,
                                border: 'none',
                                fontSize: '1em',
                                cursor: 'pointer',
                                fontFamily: settings?.font_family,
                                width: "70%",
                                padding: ' 10px 0',
                                textAlign: 'center',
                                alignContent: 'center',
                                transform: isInCart ? 'translateY(10px)' : 'translateY(0)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                            onClick={() => {
                                if (!isInCart) {
                                    addItem(getProductoWDetails());
                                    setIsInCart(true);
                                } else {
                                    setClick(false);
                                }
                            }}
                        >
                            {isInCart ? 'Confirmar' : 'Agregar'}
                        </button>
                    </Box>
                ) :
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 'auto',
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            padding: "5px 15px",
                            borderRadius: "25px",
                            border: `1px solid #707070`,
                            cursor: 'pointer',
                        }}
                            onClick={() => {
                                let mensaje = `Hola, me gustaría consultar por ${producto.Nombre}`;
                                if (settings.id == 11) {
                                    mensaje = `Hola! Te consulto por el servicio de ${producto.Nombre}
El Instagram de mi marca es: 
`
                                }
                                window.open(`https://wa.me/${settings.telefono}?text=${encodeURIComponent(mensaje)}`, '_blank');
                            }}
                        >
                            <WhatsAppIcon style={{ color: 'green' }} />
                            <Typography variant="h6" sx={{
                                color: "#707070",
                                fontFamily: settings?.font_family,
                            }}>
                                Contacto
                            </Typography>
                        </div>
                    </div>
                }
            </Box>
          <Modal
                open={fullScreenImage}
                onClose={() => setFullScreenImage(false)}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => setFullScreenImage(false)}
            >
                <img 
                    src={host + producto?.Imagen} 
                    alt={producto.Nombre} 
                    style={fullScreenImageStyle}
                />
            </Modal>

            <style jsx="true">{`
                @keyframes scaleUp {
                    from {
                        transform: scale(0.8);
                        opacity: 0.5;
                    }
                    to {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            `}</style>
        </ModalProducto>
    );
}
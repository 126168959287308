import { Autocomplete, Box, Divider, Checkbox, FormControlLabel, Grid, List, ListItem, ListItemText, Modal, Paper, TextField, Typography } from "@mui/material";
import useFetch, { host } from "../../../Utils/Fetchs";
import { useEffect, useState } from "react";
import BotonLoading from "../../../Components/ABM/MUIComponents/BotonLoading";

export default function Pedidos() {
    const [estados, setEstados] = useState([]);
    const [estado, setEstado] = useState('Creado');
    const [pedidos, setPedidos] = useState([]);
    const [pedido, setPedido] = useState(null);
    const [loading, setLoading] = useState(false);
    const [nuevoEstado, setNuevoEstado] = useState();
    const [productosAgrupados, setProductosAgrupados] = useState({});

    const { getFetch, postFetch, putFetch } = useFetch();

    const [showProductosAPreparar, setShowProductosAPreparar] = useState(false);
    const [showAttributes, setShowAttributes] = useState({
        id: true,
        items: true,
        // total: false,
        nombre: true,
        telefono: false,
        barrio: true,
        direccion: false,
        hora: false,
    });

    useEffect(() => {
        getEstados();
    }, [])

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            body::-webkit-scrollbar {
                display: none;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        if (estado) {
            postPedidos();
            const intervalId = setInterval(() => {
                postPedidos();
            }, 10000);
            return () => clearInterval(intervalId);
        }
    }, [estado])

    useEffect(() => {
        const agrupados = {};
        pedidos.forEach(pedido => {
            pedido.items.forEach(item => {
                if (agrupados[item.producto]) {
                    agrupados[item.producto] += item.cantidad;
                } else {
                    agrupados[item.producto] = item.cantidad;
                }
            });
        });
        setProductosAgrupados(agrupados);
    }, [pedidos]);

    function getEstados() {
        getFetch(host + 'pedidos/estados/', true)
            .then(data => {
                setEstados(data.estados);
                setEstado(data.estados[0]);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function putPedido(estado) {
        setLoading(true);

        putFetch(host + 'pedidos/admin/', { pedido: pedido.id, estado: estado.id }, true)
            .then(data => {
                setPedido(null);
                postPedidos();
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    function postPedidos() {
        postFetch(host + 'pedidos/admin/', { estado: estado.id }, true)
            .then(data => {
                setPedidos(data.pedidos);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function formatHora(hora) {
        const date = new Date(hora);
        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit'
        };
        const formattedTime = date.toLocaleTimeString('es-ES', timeOptions);
        return `Hora: ${formattedTime}`;
    }

    return (
        <Grid container>
            <Grid item container sx={{
                padding: 2,
                gap: 2,
                flexWrap: 'nowrap',
                alignItems: 'flex-end',
            }}>
                <Autocomplete
                    options={estados}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(event, newValue) => {
                        setEstado(newValue);
                    }}
                    value={estado}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Estados" variant="outlined" />
                    }
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={showAttributes.id}
                        onChange={(e) => setShowAttributes({ ...showAttributes, id: e.target.checked })}
                        name="id"
                        color="primary"
                    />}
                    label="ID"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={showAttributes.items}
                        onChange={(e) => setShowAttributes({ ...showAttributes, items: e.target.checked })}
                        name="items"
                        color="primary"
                    />}
                    label="Items"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={showAttributes.nombre}
                        onChange={(e) => setShowAttributes({ ...showAttributes, nombre: e.target.checked })}
                        name="nombre"
                        color="primary"
                    />}
                    label="Nombre"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={showAttributes.fecha_hora}
                        onChange={(e) => setShowAttributes({ ...showAttributes, fecha_hora: e.target.checked })}
                        name="hora"
                        color="primary"
                    />}
                    label="Hora"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={showAttributes.telefono}
                        onChange={(e) => setShowAttributes({ ...showAttributes, telefono: e.target.checked })}
                        name="telefono"
                        color="primary"
                    />}
                    label="Telefono"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={showAttributes.direccion}
                        onChange={(e) => setShowAttributes({ ...showAttributes, direccion: e.target.checked })}
                        name="direccion"
                        color="primary"
                    />}
                    label="Direccion"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={showAttributes.barrio}
                        onChange={(e) => setShowAttributes({ ...showAttributes, barrio: e.target.checked })}
                        name="barrio"
                        color="primary"
                    />}
                    label="Barrio"
                />
                {/* <FormControlLabel
                    control={<Checkbox
                        checked={showAttributes.total}
                        onChange={(e) => setShowAttributes({ ...showAttributes, total: e.target.checked })}
                        name="total"
                        color="primary"
                    />}
                    label="Total $"
                /> */}
                <Divider orientation="vertical" flexItem />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showProductosAPreparar}
                            onChange={(e) => setShowProductosAPreparar(!showProductosAPreparar)}
                            name="showProductosAPreparar"
                            color="primary"
                        />
                    }
                    label="Mostrar Productos a Preparar"
                />
            </Grid>
            <Grid container>
                <Grid item sx={{ width: 'calc(100% - 300px)' }}>
                    <Grid container sx={{
                        height: "calc(100vh - 100px)",
                        padding: 3,
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                    }}>
                        {pedidos.map((pedido, index) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} key={index} sx={{ padding: 2 }}>
                                    <Paper
                                        sx={{
                                            width: '100%',
                                            padding: 2,
                                            cursor: 'pointer',
                                            bgcolor: 'rgba(0,0,0,0.1)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                        onClick={() => setPedido(pedido)}
                                    >
                                        {showAttributes.id &&
                                            <Typography variant="h6" sx={{
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}>ID: {pedido.id}</Typography>
                                        }
                                        {showAttributes.items &&
                                            <List>
                                                <Typography variant="h6">Productos: </Typography>
                                                {pedido.items.map((item, index) => {
                                                    return (
                                                        <ListItem key={index} >
                                                            <Typography variant="h6">{index + 1}. {item.producto} X <span style={{ fontWeight: 'bold' }}>{item.cantidad}</span></Typography>
                                                            {item.detalles.length > 0 && <Typography variant="h8" ml={'5px'}>({item.detalles?.map(detalle => detalle.tipo_producto).join(' - ')})</Typography>}
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        }
                                        {showAttributes.nombre &&
                                            <Typography variant="h8">Nombre: {pedido.nombre}</Typography>
                                        }
                                        {showAttributes.fecha_hora &&
                                            <Typography variant="h8">{formatHora(pedido.fecha_hora)}</Typography>
                                        }
                                        {showAttributes.telefono &&
                                            <Typography variant="h8">Telefono: {pedido.telefono}</Typography>
                                        }
                                        {showAttributes.barrio ?
                                            pedido.barrio !== null && pedido.barrio !== '' ?
                                                <Typography variant="h8">Barrio: {pedido.barrio}</Typography>
                                                : <Typography variant="h8" sx={{ color: 'red' }}>Retira en local</Typography>
                                            : null
                                        }
                                        {showAttributes.direccion ?
                                            pedido.direccion !== null && pedido.direccion !== '' ?
                                                <Typography variant="h8">Direccion: {pedido.direccion}</Typography>
                                                : showAttributes.barrio ? null
                                                    : <Typography variant="h8" sx={{ color: 'red' }}>Retira en local</Typography>
                                            : null
                                        }
                                        {/* {showAttributes.total ?
                                            pedido.total !== undefined ?
                                                <Typography variant="h8" sx={{
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                }}>${pedido.total}</Typography>
                                                : <Typography variant="h8" sx={{ textAlign: 'center', fontWeight: 'bold', color: 'red' }}>Sin total</Typography>
                                            : null} */}
                                    </Paper>

                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>

                {showProductosAPreparar && (
                    <Grid item sx={{
                        width: '300px',
                        position: 'fixed',
                        right: 0,
                        marginRight: 5,
                        height: '60vh',
                        overflowY: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
                        padding: 2,
                        bgcolor: 'rgba(0,0,0,0.1)',
                    }}>
                        <Typography variant="h6" gutterBottom textAlign={'center'}>
                            Productos a preparar
                        </Typography>
                        <List>
                            {Object.keys(productosAgrupados).length > 0 ?
                                Object.entries(productosAgrupados).map(([producto, cantidad], index) => {
                                    return (
                                        <Grid container key={index}>
                                            <Grid container>
                                                <Typography variant="h6" gutterBottom >
                                                    {producto}
                                                </Typography>
                                                <Typography variant="h6" gutterBottom marginLeft={2} sx={{ fontWeight: 'bold' }}>
                                                    X {cantidad}
                                                </Typography>
                                            </Grid>
                                            <Divider
                                                sx={{
                                                    width: '100%',
                                                    marginBottom: 2,
                                                }}
                                            />
                                        </Grid>
                                    )
                                })
                                :
                                <Typography variant="h6" gutterBottom textAlign={'center'}>
                                    No hay productos a preparar en este estado
                                </Typography>
                            }
                        </List>
                    </Grid>
                )}
            </Grid>
            <Modal open={pedido} onClose={() => setPedido(null)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Autocomplete
                        options={estados}
                        getOptionLabel={(option) => option.nombre}
                        onChange={(event, newValue) => {
                            setNuevoEstado(newValue);
                        }}
                        style={{ marginBottom: 20 }}
                        value={nuevoEstado}
                        renderInput={(params) => <TextField {...params} label="Estados" variant="outlined" />
                        }
                    />
                    <BotonLoading
                        funcion={() => putPedido(nuevoEstado)}
                        state={!nuevoEstado}
                        loading={loading}
                    >
                        Cambiar estado
                    </BotonLoading>
                </Box>
            </Modal>
        </Grid>
    )
}
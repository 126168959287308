import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSettings } from '../Utils/settings/Configuraciones';
import useFetch, { host } from '../Utils/Fetchs';

const SettingsContext = createContext()

function SettingsProvider({ children }) {
    const [settings, setSettings] = useState(null);
    const [wifi, setWifi] = useState(null);
    const [planes, setPlanes] = useState(null);
    const [carrito, setCarrito] = useState([]);
    const navigate = useNavigate();
    const { getTemaActual } = useSettings();
    const [carritoDrawer, setCarritoDrawer] = useState(false);
    const [descuentos, setDescuentos] = useState([]);
    const [afiliadoConfirmado, setAfiliadoConfirmado] = useState(false);
    const [publicKeyMercadoPago, setPublicKeyMercadoPago] = useState(null);

    const { postFetch, getFetch } = useFetch();

    useEffect(() => {
        let empresa = getEmpresa();
        getFetch(`${host}descuentos/${empresa}/`)
            .then(data => {
                setDescuentos(data);
            })
    }, [])

    async function getSettings() {
        let empresa = getEmpresa();
        getTemaActual(empresa)
            .then(data => {
                setSettings(data.tema)
                setPlanes(data.planes)
                setWifi(data.wifi)
                setPublicKeyMercadoPago(data.mercado_pago);
                        })
            .catch(error => navigate('/'));
    }

    function getCantidadCarrito() {
        return carrito.reduce((acc, item) => acc + item.cantidad, 0);
    }

    function isInCarrito(item) {
        return carrito.findIndex(i => i.id === item.id && JSON.stringify(i.detalles) === JSON.stringify(item.detalles))
    }

    function getCantidad(item) {
        let index = isInCarrito(item);
        if (index === -1) {
            return 0;
        }
        return carrito[index].cantidad;
    }

    function addItem(item) {
        let newCarrito = [...carrito];
        let index = isInCarrito(item);
        if (index === -1) {
            newCarrito.push({ ...item, cantidad: 1 });
            postFetch(host + 'estadisticas/agregar-al-carrito/', {
                id: item.id,
            })
        } else {
            newCarrito[index].cantidad++;
        }
        setCarrito(newCarrito);
    }

    function decreaseItem(producto) {
        let newCarrito = [...carrito];
        let quito = false;
        let index = isInCarrito(producto);
        newCarrito[index].cantidad--;
        if (newCarrito[index].cantidad === 0) {
            newCarrito.splice(index, 1);
            quito = true;
        }
        setCarrito(newCarrito);

        if (quito) {
            postFetch(host + 'estadisticas/quitar-del-carrito/', {
                id: producto.id,
            })
        }
    }

    function getPrecioTotal(producto, detalles) {
        let newTotalPrice = producto.Precio;

        detalles.sort((a, b) => {
            return (a.precio_relativo === false ? -1 : 1) - (b.precio_relativo === false ? -1 : 1);
        }).forEach(detalle => {
            let selectedTipo = detalle.tipos.find(tipo => tipo.selected)
            if (selectedTipo) {
                if (detalle.precio_relativo === true) {
                    newTotalPrice += selectedTipo.precio;
                } else {
                    newTotalPrice = selectedTipo.precio;
                }
            }
        });

        return newTotalPrice
    }

    function getEmpresa() {
        const url = window.location.href;
        const urlArray = url.split('/');
        if (urlArray[3].includes('?')) {
            return urlArray[3].split('?')[0];
        }
        return urlArray[3];
    }

    return (
        <SettingsContext.Provider value={{
            settings, wifi, getSettings, getEmpresa, planes, carrito, setCarrito,
            carritoDrawer, setCarritoDrawer,
            addItem, decreaseItem,
            getCantidadCarrito,
            isInCarrito,
            getCantidad,
            getPrecioTotal,
            descuentos,
            afiliadoConfirmado, setAfiliadoConfirmado,
            publicKeyMercadoPago, setPublicKeyMercadoPago
        }}>
            {children}
        </SettingsContext.Provider>
    );
};

export { SettingsProvider, SettingsContext };

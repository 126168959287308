import React, { useState, useEffect, useContext } from 'react';
import { useProducto } from '../../../Utils/UProducto';
import useFetch, { host } from '../../../Utils/Fetchs';
import { useCategoria } from '../../../Utils/UCategoria';
import DefaultModal from "../../../Components/ABM/MUIComponents/DefaultModal";
import { Button, Grid, TextField, Typography, Autocomplete, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, getListItemSecondaryActionClassesUtilityClass, Box } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import BotonLoading from "../../../Components/ABM/MUIComponents/BotonLoading";
import DeleteIcon from '@mui/icons-material/Delete';
import { UserContext } from '../../../contexts/UserContext';
import FrameDetalles from './FrameDetalles';
import CentrarImagen from './CentrarImagen';

export default function AMModalProduct(props) {
    const { isOpen, onClose, producto, setProductos, productos, resetFiltros, detalles, setDetalles } = props;
    const [nombre, setNombre] = useState("");
    const [precio, setPrecio] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [detallesProducto, setDetallesProducto] = useState([{}]);
    const [selectedDetalles, setSelectedDetalles] = useState({});
    const [activeDetalle, setActiveDetalle] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [imagen, setImagen] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [categoriasProducto, setCategoriasProducto] = useState("");
    const [productosNombres, setProductosNombres] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemsDescripcion, setItemsDescripcion] = useState(false);
    const [items, setItems] = useState([{ id: 0, value: "" }, { id: 1, value: null },
    { id: 2, value: null }, { id: 3, value: null }, { id: 4, value: null }, { id: 5, value: null }]);
    const [unidades, setUnidades] = useState('');

    const { getCategoriasABM } = useCategoria();
    const { putProducto, getProductosNombres } = useProducto();
    const { postFetch, putFetch } = useFetch();

    const { planes } = useContext(UserContext);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        getCategoriasABM()
            .then(response => setCategorias(response))
            .catch(error => console.error('Error al cargar categorías:', error));
    }, []);

    useEffect(() => {
        getProductosNombres()
            .then(response => setProductosNombres(response))
            .catch(error => console.error('Error los nombres de los productos:', error));
    }, []);

    useEffect(() => {
        if (producto) {
            setNombre(producto.name);
            setDescripcion(producto.description);
            setPrecio(producto.precio);
            setUnidades(producto.stock);
            setImagen(producto.image);
            setCategoriasProducto(producto.categorias.map(categoria => categoria.id));
            setItemsDescripcion(producto.tipoDescripcion);
            if (producto.description !== "" && producto.description !== null) {
                let items = producto.description.split('-');
                let newItems = [];
                for (let i = 0; i < 6; i++) {
                    if (items[i]) {
                        newItems.push({ id: i, value: items[i] });
                    } else {
                        newItems.push({ id: i, value: null });
                    }
                }
                setItems(newItems);
            }
            if (producto.detalles?.length > 0 && detalles) {
                let detallesSeleccionados = {};
                let newDetalles = [];
                let detallesID = detalles.map(detalle => detalle.id);
                producto.detalles.map(detalle => {
                    if (detallesID.includes(detalle.plantilla_detalle_producto)) {
                        let detalle2 = detalles.find(detalle2 => detalle2.id === detalle.plantilla_detalle_producto);
                        detallesSeleccionados[detalle2.nombre] = true;
                        const tipos = detalle.tipos.map(tipo => {
                            let tipo2 = detalle2.tipos_producto.find(tipo2 => tipo2.id === tipo.plantilla_tipo_producto);
                            return { id: tipo2?.id, precio: tipo.precio, nombre: tipo2?.nombre };
                        });
                        newDetalles.push({ id: detalle2.id, nombre: detalle2.nombre, tipos_producto: tipos, precio_relativo: detalle2.precio_relativo, precio_porcentual: detalle2.precio_porcentual });
                    }
                });
                if (newDetalles.length > 1) {
                    setShowButtons(true);
                }
                setActiveDetalle(newDetalles[0]?.nombre);
                setSelectedDetalles(detallesSeleccionados);
                setDetallesProducto(newDetalles);
            }
        }
        else {
            setNombre("");
            setDescripcion("");
            setPrecio("");
            setCategoriasProducto([]);
            setImagen(null);
            setItemsDescripcion(false);
            setItems([{ id: 0, value: "" }, { id: 1, value: null }, { id: 2, value: null }, { id: 3, value: null },
            { id: 4, value: null }, { id: 5, value: null }]);
            setDetallesProducto([]);
            setUnidades('');
            setSelectedDetalles({});
            setActiveDetalle(null);
            setShowButtons(false);
        }
    }, [producto, detalles, setCategoriasProducto, setDetallesProducto, setSelectedDetalles, setItems, setItemsDescripcion, setNombre, setDescripcion, setPrecio, setImagen, setCategoriasProducto, setUnidades]);

    async function handlePostProducto() {
        setLoading(true);
    
        try {
            let producto = {};
            if (nombre) producto.name = nombre;
            const categoriasString = categoriasProducto.join(',');
            if (categorias) producto.categories = categoriasString;
            if (itemsDescripcion) {
                producto.tipoDescripcion = itemsDescripcion;
                producto.description = items.map(item => item.value).join('-');
            }
            if (descripcion && !itemsDescripcion) producto.description = descripcion;
            if (precio) producto.precio = precio;
            const detallesSeleccionados = detallesProducto.map(detalle => {
                if (selectedDetalles[detalle.nombre] !== undefined && selectedDetalles[detalle.nombre] !== null) {
                    return detalle;
                }
            });
            if (detallesSeleccionados && detallesSeleccionados.length > 0) {
                let detallesData = [];
                detallesSeleccionados?.map(detalle => {
                    if (detalle === undefined || detalle === null) return;
                    let tipos = [];
                    detalle?.tipos_producto?.map(tipo => {
                        tipos.push({ id: tipo.id, precio: tipo.precio === undefined ? 0 : tipo.precio });
                    });
                    detallesData.push({ id: detalle.id, tipos: tipos });
                });
                producto.detalles = detallesData;
            } else {
                producto.detalles = [];
            }
    
            let data = { stock: parseInt(unidades), producto };
            console.log(data);
            
            await postFetch(`${host}abm/product/`, data, true)
                .then(response => {
                    let newProductos = [...productos];
                    let newProducto = { ...response };
                    if (imagen) {
                        const formData = new FormData();
                        formData.append('image', imagen);
                        formData.append('id', response.id);
                        putProducto(formData)
                            .then((response2) => {
                                newProducto.image = response2.image;
                            })
                            .catch(error => console.error('Error al cargar la imagen:', error));
                    }
                    newProductos.push(newProducto);
                    setProductos(newProductos);
                    resetFiltros();
                })
                .catch(error => console.error('Error al crear producto:', error))
                .finally(() => {
                    handleCloseModal();
                });
        } catch (error) {
            alert(error.message);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log(imagen);
    }, [imagen]);

    async function handlePutProducto(id) {
        setLoading(true);
        let productoInProductos = productos.find(producto => producto.id === id);
        try {
            if (!productoInProductos) {
                alert('El producto no existe.');
                return;
            }
            let data = { id: id };
            if (nombre && nombre !== productoInProductos.name) data.name = nombre;
            const categoriasString = categoriasProducto.join(',');
            if (categorias) data.categories = categoriasString;
            if (itemsDescripcion) {
                data.tipoDescripcion = itemsDescripcion;
                data.description = items.map(item => item.value).join('-');
                while (data.description[data.description.length - 1] === '-') {
                    data.description = data.description.slice(0, -1);
                }
            }
            if (descripcion && !itemsDescripcion) data.description = descripcion;
            if (precio) data.precio = precio;
            const detallesSeleccionados = detallesProducto.map(detalle => {
                if (selectedDetalles[detalle.nombre] !== undefined && selectedDetalles[detalle.nombre] !== null) {
                    return detalle;
                }
            });
            if (unidades) data.stock = unidades;
            if (detallesSeleccionados && detallesSeleccionados.length > 0) {
                let detallesData = [];
                detallesSeleccionados?.map(detalle => {
                    if (detalle === undefined || detalle === null) return;
                    let tipos = [];
                    detalle?.tipos_producto?.map(tipo => {
                        if (tipo.precio === undefined) { tipo.precio = 0 }
                        tipos.push({ id: tipo.id, precio: tipo.precio });
                    });
                    detallesData.push({ id: detalle.id, tipos: tipos });
                });
                data.detalles = detallesData;
            }

            await putFetch(`${host}abm/product/`, data, true)
                .then(response => {
                    let newProductos = [...productos];
                    let newProducto = { ...response };
                    if (imagen !== productoInProductos.image ) {
                        const formData = new FormData();
                        formData.append('image', imagen);
                        formData.append('id', response.id);
                        putProducto(formData)
                            .then((response2) => {
                                newProducto.image = response2.image;
                            })
                            .catch(error => console.error('Error al cargar la imagen:', error));
                    }
                    newProductos[newProductos.findIndex(producto => producto.id === id)] = newProducto;
                    setProductos(newProductos);
                    resetFiltros();
                })
                .catch(error => console.error('Error al editar producto:', error))
                .finally(() => {
                    handleCloseModal();
                });
        } catch (error) { 
            alert(error.message);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && isOpen && nombre !== "" && categoriasProducto.length > 0) {
                event.preventDefault();
                producto ? handlePutProducto(producto.id) : handlePostProducto();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => { 
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [producto, isOpen, handlePutProducto, handlePostProducto]);

    const handleDescripcionChange = (event) => {
        setDescripcion(event.target.value);
    };

    const agregarItem = () => {
        let newItems = [...items];
        for (let i = 0; i < items.length; i++) {
            if (items[i].value === null) {
                newItems[i].value = "";
                setItems(newItems);
                return;
            }
        }
    }

    const quitarItem = (id) => {
        let newItems = [...items];
        newItems[id].value = null;
        setItems(newItems);
    }

    const handlePrecioChange = (event) => {
        const inputPrice = event.target.value;
        if (/^\$?\d*\.?\d*$/.test(inputPrice)) {
            setPrecio(inputPrice);
        }
    };

    const handleImagenChange = (event) => {
        const MAX_FILE_SIZE_MB = 20;
        let file = event.target?.files[0] || event instanceof File && event;
        if (file?.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            alert(`La imagen debe ser menor a ${MAX_FILE_SIZE_MB} MB`);
            setImagen(null);
        } else {
            let img = document.createElement("img");
            img.src = URL.createObjectURL(file);
            img.onerror = () => {
                alert('Hubo un error al cargar la imagen.');
            };
            img.onload = () => {
                let canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");

                const originalWidth = img.width;
                const originalHeight = img.height;

                const maxDimension = 800;

                let newWidth, newHeight;
                if (originalWidth > originalHeight) {
                    newWidth = maxDimension;
                    newHeight = (originalHeight * maxDimension) / originalWidth;
                } else {
                    newHeight = maxDimension;
                    newWidth = (originalWidth * maxDimension) / originalHeight;
                }

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob((blob) => {
                    let newFile = new File([blob], "image.jpeg", { type: "image/jpeg" });
                    setImagen(newFile);
                }, "image/jpeg");
            };
        };
    }

    const handleCategoriasProducto = (idCategoria, isChecked) => {
        if (isChecked) {
            setCategoriasProducto(prevState => [...prevState, idCategoria]);
        } else {
            setCategoriasProducto(prevState => prevState.filter(id => id !== idCategoria));
        }
    };

    const handleCloseModal = () => {
        setNombre("");
        setPrecio("");
        setUnidades('');
        setDescripcion("");
        setImagen(null);
        setCategoriasProducto([]);
        setItemsDescripcion(false);
        setItems([{ id: 0, value: "" }, { id: 1, value: null }, { id: 2, value: null }, { id: 3, value: null },
        { id: 4, value: null }, { id: 5, value: null }]);
        setSelectedDetalles({});
        setDetallesProducto([{}]);
        setActiveDetalle(null);
        setShowButtons(false);
        onClose();
    };

    return (
        <DefaultModal
            open={isOpen}
            onClose={() => {
                handleCloseModal();
            }}
        >
            <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                xs={12}
            >
                <Grid
                    container
                    item
                    xs={8}
                    justifyContent="flex-end"
                    style={{ gap: "30px" }}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        style={{ marginBottom: "2vh", width: '100%', textAlign: "center" }}
                    >
                        {producto ? "Editar Producto" : "Crear Producto"}
                    </Typography>
                    <Autocomplete
                        freeSolo
                        value={nombre}
                        options={productosNombres}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.nombre;
                        }}
                        filterOptions={(options, { inputValue }) =>
                            options.filter((option) => {
                                if (!inputValue) return false;
                                const startsWithInput = option.toLowerCase().startsWith(inputValue.toLowerCase());
                                return startsWithInput;
                            })
                        }
                        fullWidth
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setNombre(newValue);
                            } else if (newValue?.inputValue) {
                                setNombre(newValue.inputValue);
                            } else if (newValue) {
                                setNombre(newValue.nombre);
                            } else {
                                setNombre('');
                            }
                        }}
                        onInputChange={(event, newInputValue) => {
                            setNombre(newInputValue);
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Nombre"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {nombre ? 35 - nombre.length : 35}
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    maxLength: 35,
                                }}
                            />
                        }
                    />
                    <Button
                        variant='contained'
                        color="primary"
                        fullWidth
                        style={{ margin: 'auto', width: '40%', opacity: itemsDescripcion ? '0.5' : '1' }}
                        onClick={() => setItemsDescripcion(false)}
                    >
                        Descripcion
                    </Button>
                    <Button
                        variant='contained'
                        color="primary"
                        style={{ margin: 'auto', width: '40%', opacity: !itemsDescripcion ? '0.5' : '1' }}
                        onClick={() => setItemsDescripcion(true)}
                    >
                        Items
                    </Button>
                    <Grid style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        {!itemsDescripcion ?
                            <TextField
                                label="Descripcion"
                                variant="outlined"
                                value={descripcion}
                                fullWidth
                                multiline
                                onChange={handleDescripcionChange}
                                inputProps={{
                                    maxLength: 160,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{descripcion ? 160 - descripcion.length : 160}</InputAdornment>
                                }}
                            />
                            :
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Descripción en items</TableCell>
                                            <TableCell sx={{ textAlign: 'right' }}>
                                                <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                                                    <Grid item>
                                                        <Button color="primary" variant='outlined' style={{
                                                            marginBottom: '10px', marginTop: '10px',
                                                        }} disabled={items.filter(item => item.value !== null).length >= 6} onClick={agregarItem}>
                                                            Agregar
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items?.map((item) => (
                                            item.value !== null &&
                                            <TableRow >
                                                <TableCell component="th" scope="row">
                                                    <TextField
                                                        style={{ width: '140%' }}
                                                        value={item.value}
                                                        onChange={(event) => {
                                                            const updatedItems = [...items];
                                                            updatedItems[item.id].value = event.target.value;
                                                            setItems(updatedItems);
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <DeleteIcon
                                                        onClick={() => items.filter(i => i.value !== null).length > 1 && item.id !== 0 ? quitarItem(item.id) : null}
                                                        style={{
                                                            fontSize: '23px',
                                                            color: items.filter(i => i.value !== null).length > 1 && item.id !== 0 ? 'red' : 'gray',
                                                            marginLeft: '80%',
                                                            borderRadius: '50%',
                                                            cursor: 'pointer',
                                                            transition: 'linear 0.5s',
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Grid>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={imagen === null ? <CloudUploadIcon /> : <DoneIcon />}
                        style={{ margin: '0 auto 0 0', width: '100%' }}
                    >
                        {imagen === null ? "Seleccionar Imagen" : "Imagen Seleccionada"}
                        <VisuallyHiddenInput type="file" accept="image/*,image/heic,image/heif" onChange={handleImagenChange} />
                    </Button>
                    {imagen !== null ?
                        <Box style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          {/* <CentrarImagen onSubmit={handleImagenChange} image={imagen} setImage={setImagen} /> */}
                            <Button
                                component="label"
                                variant="contained"
                                style={{ width: '80%', height: '30%', margin: '0 auto', marginTop: '10px' }}
                                onClick={() => setImagen(null)}
                            >
                                Borrar
                            </Button>
                        </Box>
                        : null}
                    <TextField
                        label="Precio"
                        variant="outlined"
                        value={precio}
                        fullWidth
                        onChange={handlePrecioChange}
                        placeholder="$"
                    />
                     <TextField
                        label="Unidades en stock"
                        variant="outlined"
                        value={unidades}
                        fullWidth
                        onChange={(event) => {
                            const input = event.target.value;
                            if (/^\d*$/.test(input)) {
                                setUnidades(input);
                            }
                        }}
                    />
                    <Grid container >
                        {(planes.includes('6')) ?
                            <FrameDetalles detalles={detalles} setDetalles={setDetalles} detallesProducto={detallesProducto} setDetallesProducto={setDetallesProducto}
                                selectedDetalles={selectedDetalles} setSelectedDetalles={setSelectedDetalles} activeDetalle={activeDetalle} setActiveDetalle={setActiveDetalle}
                                showButtons={showButtons} setShowButtons={setShowButtons}
                            /> : null
                        }
                    </Grid>
                    <FormControl sx={{ margin: '0 auto 0 0' }} component="fieldset" variant="standard">
                        <Typography variant="h7"> Categorias </Typography>
                        <FormGroup>
                            {categorias?.map((categoria) => (
                                <FormControlLabel
                                    key={categoria.id}
                                    control={
                                        <Checkbox
                                            checked={categoriasProducto.includes(categoria.id)}
                                            onChange={(event) => handleCategoriasProducto(categoria.id, event.target.checked)}
                                            name={categoria.name}
                                        />
                                    }
                                    label={categoria.name}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                    <BotonLoading
                        funcion={producto ? () => handlePutProducto(producto.id) : handlePostProducto}
                        state={!(nombre !== "" && categoriasProducto.length > 0)}
                        loading={loading}
                    >
                        {producto ? "Editar" : "Crear"}
                    </BotonLoading>
                </Grid>
            </Grid>
        </DefaultModal>
    );
}
